import React, { useContext, useEffect, useState } from "react";
import FormControl from "../components/form/FormControl";
import { Field, Form, Formik } from "formik";
import SubmitButton from "../components/SubmitButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../services/config.json";
import UploadProduct from "../components/form/UploadProduct";
import useFetch from "../customHooks/useFetch";
import Loader from "../components/Loader";
import usePost from "../customHooks/usePost";
import { MenuActive } from "../context/MenuActiveContext";
import { MyContext } from "../context/MyContext";
import swal from "sweetalert";
import { toast } from "react-toastify";

const EditProduct = () => {
  const {id} = useParams();
  const [productData, setProductData] = useState()
  const [attToRemove, setAttToRemove] = useState([])
  const { setMunuActiv } = useContext(MenuActive);
  const { rolesData } = useContext(MyContext);
  const navigate = useNavigate();
  useEffect(() => {
    if(rolesData.role_id === 2) {
      setMunuActiv([true,"ProductManagement"])
    }
    else {
      setMunuActiv([true,"Products"])
    }
  }, [])
  
  const [fileList, setFileList] = useState([
  ]);
  const {loading,data:data1} = useFetch(`dashboard/editProduct/${id}`)
  const {loading:loading2,data:data2} = useFetch(`viewCategories`)
useEffect(() => {
 if(data1) {
  if(data1.status === false) {
    navigate(`/${config.demo}product/list`)
  }
  else {
    setProductData(data1.data[0])
    let attention = []
    let att = data1?.data[0]?.attachment_urls
    for (let item in att) {  
      attention.push({
        uid: `${item}`,
        name: `${att[item].split("/")[att[item].split("/").length -1]}`,
        status: 'done',
        url: `${att[item]}`,
      })
      console.log(att[item],"dsfsdffsdds")
    }
    setFileList(attention)
  }
 }
}, [data1]);

const [res, apiMethod] = usePost()

const hanldefileUpdate = (values) => {
  setFileList(values)
}

 
  useEffect(() => {
    if(res?.data) {
      navigate(`/${config.demo}product/list`)
    }
   }, [res.data]);

  const handleSubmit = async (values) => {
    console.log(values)
    let formdata = new FormData();
    let Feilds = {
      "name": "Product Name",
      "stock":"Quantity in Stock",
      "price":"Price",
      "category_id":"Category",
    }
    const requirdFeild = []
    console.log(values)

    if(!fileList || fileList.length < 1) {
      requirdFeild.push("Product Images")
    }
    for (let item  in values) {
      if(values[item]) {
      }
      else {
        requirdFeild.push(Feilds[item])
      }
      formdata.append(item,values[item]);
    }
    formdata.append("owner_type","vendor")
    if(requirdFeild.length > 0) {
      swal({
        title: "Must Required",
        text: requirdFeild.join(","),
        icon: "warning",
        dangerMode: true,
      });
    }
    else {
      if(fileList.length) {
        for (let index = 0; index < fileList.length; index++) {
          formdata.append(`attachments[]`,fileList[index]?.originFileObj ?? fileList[index]?.url)  
        }
        for (let index2 = 0; index2 < attToRemove.length; index2++) {
          formdata.append(`attachments_to_remove[]`,attToRemove[index2])
          
        }
      }
      apiMethod(`dashboard/updateProduct/${id}`,formdata);
    }
  };
  useEffect(() => {
    if(res.data) {
     toast.success(res.data.message);
    }
   }, [res.data])
  if(loading || loading2) return <Loader />;
  let initialValues = {};
  if(data1?.data.length > 0) {
    initialValues = {
      name: data1.data[0].product_name ?? "",
      // detail: data1.data[0].product_detail ?? "",
      price: Number(data1.data[0].price).toFixed(0) ?? "",
      category_id: data1.data[0]?.category_id ?? "",
      stock: data1.data[0]?.product_stock ?? "",
      shop_id: data1.data[0]?.id ?? "",
      part_type: data1.data[0]?.part_type ?? "",
      v_number: data1.data[0]?.v_number ?? "",
      condition: data1.data[0]?.condition ?? "",
      part_number: data1.data[0]?.part_number ?? "",
      year: data1.data[0]?.year ?? "" ,
      car_brand: data1.data[0]?.car_brand ?? "",
      model: data1.data[0]?.model ?? "", 
    }
  };

  return (
    <section className="SignInPage">    
      <div className=" flex items-center px-10 py-5  productRes">
        <div className="SignInPage__right_ w-100 h-full">
          <Link to={`/${config.demo}product/list`} className="arrowArrow flex items-center  gap-2">
            <img src={require("../dist/webImages/arrow-left2.png")} alt="" />
            <span>Add New Product</span>
          </Link>
          <br />
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            <Form name="myForm">
              <UploadProduct removeatt={setAttToRemove} attremove={attToRemove} updatess={(item) => hanldefileUpdate(item)} filelis={fileList} />

              <div className="SignInPage__rightBox grid grid-cols-2 gap-5">
                
              <div className="SignInPage__rightBoxl">
                 
                 <FormControl
                   name="name"
                   placeholder="Product Name"
                   label={"Product Name"}
                   className="w-full"
                   control="input"
                 />
                  <FormControl
                  name="part_number"
                   type={"number"}
                   placeholder="Part Number"
                   label={"Part Number"}
                   className="w-full"
                   control="input"
                 />
                  <FormControl
                   name="car_brand"
                   type={"text"}
                   placeholder="Car Brand"
                   label={"Car Brand"}
                   className="w-full"
                   control="input"
                 />
                  <FormControl
                   name="model"
                   type={"text"}
                   placeholder="Model"
                   label={"Model"}
                   className="w-full"
                   control="input"
                 />
                  <FormControl
                   name="year"
                   type={"number"}
                   placeholder="Year"
                   label={"Year"}
                   className="w-full"
                   control="input"
                 />
              <FormControl
                   name="v_number"
                   label={"V4/V6/V8"}
                   className="w-full"
                   control="input"
                   placeholder="V4/V6/V8"
                
                 />
               
               </div>
               <div className="SignInPage__rightBoxr">
              
                  <FormControl
                   name="category_id"
                   label={"Category"}
                   className="w-full"
                   control="select"
                   custom_label_name={"name"}
                   firstSelect={"Category"}
                   customer_value_name={"id"}
                   selectList={[
                     {
                     "name":"engine",
                     "id":"1",
                   },
                     {
                     "name":"powertrain&chassis",
                     "id":"2",
                   },
                     {
                     "name":"electrical",
                     "id":"3",
                   },
                     {
                     "name":"body",
                     "id":"4",
                   },
                     {
                     "name":"transmission",
                     "id":"5",
                   },
                     {
                     "name":"AC",
                     "id":"6",
                   },
                 ]}  
                 />
                    <FormControl
                   name="condition"
                   label={"Condition"}
                   className="w-full"
                   control="select"
                   custom_label_name={"name"}
                   customer_value_name={"id"}
                   selectList={[
                     {
                     "name":"used",
                     "id":"1",
                   },
                     {
                     "name":"new",
                     "id":"2",
                   },
                 ]}  
                 />

<div className="checkc mt-3">
                    <div className="checkc_">
                    <div className="flex flex-wrap gap-4">
<label className="flex gap-1">
              <Field type="radio" name="part_type" value="1" />
              Genuine
            </label>
            <label className="flex gap-1">
              <Field type="radio" name="part_type" value="2" />
              After Market
            </label>
</div>
                    </div>
                  </div>
                 <FormControl
                   name="price"
                   placeholder="Price"
                   label={"Price"}
                   className="w-full"
                   control="input"
                   type="number"
                 />
                
                 <FormControl
                   name="stock"
                   type={"number"}  
                   placeholder="Quantity"
                   label={"Quantity in Stock"}
                   className="w-full"
                   control="input"
                 />
                
             <SubmitButton
               props={{
                 class: "btn btn-primary  block mt-5 submit",
                 text: "Submit",
               }}
              buttonLoading={res.isLoading}
             />
               </div>
                <div>
               
                
                </div>
              </div>
            

            </Form>
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default EditProduct;
