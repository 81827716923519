import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import * as yup from "yup";
import { Link, useNavigate, useParams } from 'react-router-dom';
import config from "../services/config.json";
import FormControl from '../components/form/FormControl';
import SubmitButton from '../components/SubmitButton';
import usePost from '../customHooks/usePost';
import { MenuActive } from '../context/MenuActiveContext';
import { toast } from 'react-toastify';
import upload from "../dist/webImages/filedocumet.png";
import swal from 'sweetalert';

const CreateCategory = () => {
    const navigate = useNavigate();
    const { setMunuActiv } = useContext(MenuActive);
    const [imgPath2, setImgPath2] = useState();
    const [coverImage, setCoverImage] = useState("");
    const [res, apiMethod] = usePost()
    useEffect(() => {
        setMunuActiv([true,"Category"])
     
        }, [])


    const handleUpload2 = (e) => {
      const file = e.target.files[0];
      if(e.target.files[0]) {
        let path = (window.URL || window.webkitURL).createObjectURL(file);
        setImgPath2(path);
        setCoverImage(file);
      }
  
  }
    const initialValues = {
      category_name: "",
  }
    const validationSchema = yup.object({
      category_name: yup.string().required(),
    })
    const handleSubmit = async (values,{ resetForm }) => {
        let formdata = new FormData();
        for (let item in values) {
            formdata.append(item,values[item])
          }
          const requirdFeild = []
          if(!coverImage) {
            requirdFeild.push("Images")
            swal({
              title: "Must Required",
              text: requirdFeild.join(","),
              icon: "warning",
              dangerMode: true,
            });
          }
          else {
            
            formdata.append("category_image",coverImage)
            apiMethod(`dashboard/storeCategory`,formdata);
          }
    }
    useEffect(() => {
        if(res.data) {
          if(res.data.status === "false") {
            toast.error(res.data.message);
          }
          else {
            toast.success(res.data.message);
            navigate(`/${config.demo}category`);
          }
        }
       
      }, [res.data,navigate])

  return (
   <>
      <Link to={`/${config.demo}login`} className="arrowArrow flex items-center ">
            <img src={require("../dist/webImages/arrow-left2.png")} alt="" />
            <span className="font-medium">Edit Category</span>
          </Link>
   
   <br />
   <br />

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange
          >
            <Form name="myForm">
                <div className='w-1/2'>
                <div className={`shopcover ${imgPath2 ? "axcc": ""}`}>
                        <label htmlFor='cover'>
                    <Field
        type="file"
        id={"cover"}
        style={{ display: "none" }}
        name={"cover"}
        onChange={(e) => handleUpload2(e)}
        accept="image/*"
      />
            
            <img src={imgPath2 || upload} alt="" />
           {!imgPath2 && <div className='uploadd'> Upload Image</div>}
            </label>
          </div>


            <FormControl
                    name="category_name"
                    placeholder="Name"
                    label={"Name"}
                    className="w-full"
                    control="input"
                    />
                    </div>

<SubmitButton
       props={{
            class: "btn btn-primary  block mt-5 submit",
            text: "Create Category",
                  
            }}
            buttonLoading={res.isLoading}/>
                </Form>
                </Formik>

   </>
  )
}

export default CreateCategory
