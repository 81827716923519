import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pusher from 'pusher-js';
import { getTokenSession } from './utils/common';

const ProductChat = () => {
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const productId = 11;
    const userId = 90;
    const vendorId = 33;
    axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenSession()}`,
    };

    useEffect(() => {
        const pusher = new Pusher('97feeb200ba198ebe195', {
            cluster: 'ap2',
        });
        const channel = pusher.subscribe(`chat_user${userId}_shop${vendorId}`);

        pusher.connection.bind('connected', () => {
            console.log('Pusher connected');
            console.log('Subscribed to channel: ' + channel.name);
        });

        pusher.connection.bind('disconnected', () => {
            console.log('Pusher disconnected');
        });

        pusher.connection.bind('connecting', () => {
            console.log('Pusher connecting');
        });

        pusher.connection.bind('state_change', (states) => {
            console.log('Pusher state changed', states);
            if (states.current === 'disconnected') {
                console.log('Attempting to reconnect...');
                pusher.connect();
            }
        });

        pusher.connection.bind('error', (err) => {
            console.error('Pusher error', err);
        });

        channel.bind('chat', (data) => {
            console.log('Received message:', data);
            setMessages((prevMessages) => [...prevMessages, data]);
        });

        return () => {
            pusher.unsubscribe('public');
        };
    }, []);

    const sendMessage = (event) => {
        event.preventDefault();
        axios.post('http://192.168.60.41/example-app/public/api/chat/sendMessage', {
            user_id: userId,
            shop_id: vendorId,
            message: messageInput.trim(),
        }, {
            headers: {
                'X-Socket-Id': Pusher.instances[0].connection.socket_id,
            }
        })
            .then((res) => {
                console.log('Sent message:', res.data);
                setMessages((prevMessages) => [...prevMessages, res.data]);
                setMessageInput(''); // Clear the input field after sending
            });
    };

    return (
        <div>
            <ul id="messages">
                {
                    console.log(messages)
                }
                {messages && messages.map((item, index) => (
                    <li key={ index}>{item?.message?.message || item?.message}</li> // Ensure we only render item.message
                ))}
            </ul>
            <input
                id="message-input"
                type="text"
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
            />
            <button id="send-message-btn" onClick={sendMessage}>Send</button>
        </div>
    );
};

export default ProductChat;
