import React, { useContext, useEffect, useState } from 'react'
import { MenuActive } from '../context/MenuActiveContext';
import DashboardMessage from '../components/DashboardMessage';
import config from "../services/config.json";
import Tables from './Tables';
import useFetch from '../customHooks/useFetch';
const Category = () => {
    const { setMunuActiv } = useContext(MenuActive);
    const {loading,data} = useFetch("dashboard/viewCategories")
    const [categoriesData, setCategoriesData] = useState("");
    useEffect(() => {
      setMunuActiv([true,"Category"])
      if(data) {
        setCategoriesData(data?.data)
      }
      }, [data])
  return (
    <div>
         <DashboardMessage
             data={{
                button: {
                  label: "Add Category",
                  link: `/${config.demo}category/create`
                }
              }}
         
         />
<div className="categoryBody">
<Tables  datass={categoriesData}  update={setCategoriesData}   />
</div>
      
    </div>
  )
}

export default Category
