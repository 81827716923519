import React, { useContext, useEffect,useState, useReducer, useRef } from "react";
import { MenuActive } from "../context/MenuActiveContext";
import {  FaImage, FaMagnifyingGlass } from "react-icons/fa6";
import { IoMdSend } from "react-icons/io";
import Echo from 'laravel-echo';
import axios from 'axios';
import Pusher from 'pusher-js';
import { getTokenSession } from '../utils/common';
import { MyContext } from "../context/MyContext";
import Loader from "../components/Loader";
import useFetch from "../customHooks/useFetch";
import config from "../services/config.json";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import userImage from "../dist/webImages/dummy-profile-1.jpg";

const initialState = {
    galleryActive: false,
    updateImage: {
        image: "",
        message: "",
        value: ""
    },
    data: {
        activelist: [],
        activeChat:{},
        chatdata: [
            ]
    }
};  

function reducer(state, action) {
    switch (action.type) {
        case "SET_GALLERY_ACTIVE":
            return { ...state, galleryActive: action.payload };
        case "SET_UPDATE_IMAGE":
            return { ...state, updateImage: action.payload };
        case "SET_ACTIVE_DATA":
            return {  ...state,   data: {  ...state.data,  activelist: action.payload} }
        case "SET_ACTIVE_CHAT_DATA":
            return {  ...state,   data: {  ...state.data,  activeChat: action.payload} }
        case "SET_CHAT_ALL_DATA":
            return {  ...state,   data: {  ...state.data,  chatdata: action.payload} }
            case "SET_CHAT_DATA":
                const { date, ...newChatData } = action.payload;
            // Find the index of the date in the chat data
            const dateIndex = state.data.chatdata.findIndex(chat => chat.date === date);
            if (dateIndex !== -1) {
                const updatedChatData = [...state.data.chatdata];
                updatedChatData[dateIndex] = {
                    ...updatedChatData[dateIndex],
                    chat_data: [...updatedChatData[dateIndex].chat_data, newChatData]
                };
                return {
                    ...state,
                    data: {
                        ...state.data,
                        chatdata: updatedChatData
                    }
                };
            }

            // If date doesn't exist, add new date and chat data
            return {
                ...state,
                data: {
                    ...state.data,
                    chatdata: [
                        ...state.data.chatdata,
                        {
                            date: date,
                            chat_data: [newChatData]
                        }
                    ]
                }
            };
        default:
            return state;
    }
}

const Discussions = () => {
    const [loading, setLoading] = useState(true);
    const [loading3, setLoading3] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const { rolesData } = useContext(MyContext);
    const { loading2, data: chatlist } = useFetch(`chat/userChatList/${rolesData.shop_id}`);
    const { setMunuActiv } = useContext(MenuActive);
    const [state, dispatch] = useReducer(reducer, initialState);
    const chatContainerRef = useRef(null);
    const fileInputRef = useRef(null);
    const [onlineUsers, setOnlineUsers] = useState([]);
  
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getTokenSession()}`,
    };
  
    const { galleryActive, updateImage, data } = state;
    const { activelist, activeChat, chatdata } = data;
  
    useEffect(() => {
      setMunuActiv([true, "discussions"]);
    }, [setMunuActiv]);
  
    useEffect(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }, [chatdata]);
  
    useEffect(() => { 
      if (chatlist) {
        setLoading(false);
      }
      if (chatlist && chatlist.data.length > 0) {
        let dataa = chatlist.data;
        dispatch({
          type: "SET_ACTIVE_DATA",
          payload: dataa,
        });
        dispatch({
          type: "SET_ACTIVE_CHAT_DATA",
          payload: dataa[0],
        });

        let filterlistdata = dataa
        filterlistdata[0].unread_chat_count = 0
        setFilterList(filterlistdata)
        axios.post(`${config.apiEndPoint}chat/updateMessageStatus`,{
          "id": dataa[0].sender_id,
          "user_type": "vendor",
          "sender_id": dataa[0].sender_id,
          "receiver_id": dataa[0].receiver_id,
          "message_sender_id": dataa[0].sender_id,
        })
          .then((response) => {
            axios.get(`${config.apiEndPoint}chat/getUserChat/${dataa[0].sender_id}/${rolesData.shop_id}`)
            .then((response) => {
              setLoading(false);
              dispatch({
                type: "SET_CHAT_ALL_DATA",
                payload: response.data.data,
              });
            })
            .catch((error) => {
              if (error.response.status === 401) toast.error(error.response.data.message);
              else toast.error(error.response.data.errorMessage);
            });
          })
          .catch((error) => {
            if (error.response.status === 401) toast.error(error.response.data.message);
            else toast.error(error.response.data.errorMessage);
          });
      }
    }, [chatlist]);
  
    useEffect(() => {
        const echo = new Echo({
            broadcaster: 'pusher',
            key: '18e48a20e5b3b8af7894',
            cluster: 'ap2',
            encrypted: true,
            authEndpoint: `${config.apiEndPoint}chat/broadcasting/auth`, // Ensure correct endpoint path
            auth: {
                headers: {
                    Authorization: `Bearer ${getTokenSession()}`,
                },
            },
        });
        
        // Handle Pusher connection errors
        echo.connector.pusher.connection.bind('error', function (err) {
            console.log('Pusher error:', err);
        });
        
        // Join the chatroom channel
        const channel = echo.join('chatroom');
        // Handle presence channel events
        channel.here((users) => {
            let userss = []
            for (let index = 0; index < users.length; index++) {
                userss.push(users[index].id)
            }
            setOnlineUsers(userss);
            console.log('Here:', users);
        });
        
        channel.joining((user) => {
            setOnlineUsers((prevUsers) => [...prevUsers, user.id]);
            console.log('User joining:', user);
        });
        
        channel.leaving((user) => {
            let userss = []
            for (let index = 0; index < user.length; index++) {
                userss.push(user[index].id)
            }
            setOnlineUsers((prevUsers) => prevUsers.filter((u) => u !== user.id));
            console.log('User leaving:', user);
        });
    
        // Clean up: leave the channel when component unmounts
        return () => {
          echo.leave('chatroom');
        };
      }, []);
      
      
      
  
    useEffect(() => {
      if (activeChat) {
        const pusher = new Pusher('18e48a20e5b3b8af7894', {
          cluster: 'ap2',
          encrypted: true,
        });
  
        const channel = pusher.subscribe(`chat_user${activeChat.sender_id}_shop${rolesData.shop_id}`);
        pusher.connection.bind('connected', () => {
          console.log('Pusher connected');
          console.log('Subscribed to channel: ' + channel.name);
        });
  
        pusher.connection.bind('disconnected', () => {
          console.log('Pusher disconnected');
        });
  
        pusher.connection.bind('connecting', () => {
          console.log('Pusher connecting');
        });
  
        pusher.connection.bind('state_change', (states) => {
          console.log('Pusher state changed', states);
        });
  
        pusher.connection.bind('error', (err) => {
          console.error('Pusher error', err);
        });
  
        channel.bind('chat', (data) => {
          console.log(data)
          const { id,receiver_id, messages, message_sender_id,sender_id } = data.message;
          const currentTime = new Date().toISOString().split('T')[0];
          axios.post(`${config.apiEndPoint}chat/updateMessageStatus`,{
            "id": sender_id,
            "user_type": "vendor",
            "sender_id": sender_id,
            "receiver_id": receiver_id,
            "message_sender_id": sender_id,
          })
            .then((response) => {

            })
            .catch((error) => {
              if (error.response.status === 401) toast.error(error.response.data.message);
              else toast.error(error.response.data.errorMessage);
            });
          dispatch({
            type: "SET_CHAT_DATA",
            payload: {
              id: id,
              key: new Date().getUTCMilliseconds(),
              image: updateImage.image,
              messages: messages,
              message_sender_id: message_sender_id ?? rolesData.id,
              sender_id: activeChat.sender_id,
              receiver_id: rolesData.shop_id,
              date: currentTime,
            },
          });
          dispatch({
            type: "SET_UPDATE_IMAGE",
            payload: {
              image: "",
              message: "",
              value: "",
            },
          });
        });
  
        return () => {
          channel.unbind_all();
          channel.unsubscribe();
        };
      }
    }, [activeChat, rolesData.shop_id]);


      useEffect(() => {
        if (activeChat) {
          const pusher2 = new Pusher('18e48a20e5b3b8af7894', {
            cluster: 'ap2',
            encrypted: true,
          });
    
          const channel2 = pusher2.subscribe(`user_sidebar_list`);
          pusher2.connection.bind('connected', () => {
            console.log('Pusher connected2');
            console.log('Subscribed to channel2: ' + channel2.name);
          });
    
          pusher2.connection.bind('disconnected', () => {
            console.log('Pusher disconnected2');
          });
    
          pusher2.connection.bind('connecting', () => {
            console.log('Pusher connecting2');
          });
    
          pusher2.connection.bind('state_change', (states) => {
            console.log('Pusher state changed2', states);
          });
    
          pusher2.connection.bind('error', (err) => {
            console.error('Pusher error2  ', err);
          });
    
          channel2.bind('sidebar_list', (data) => {
            const {vendor_id,list} = data.userListData.vendor_list;
            setFilterList(list)
          
              console.log(data,"sidebar_list")
          });
    
          return () => {
            channel2.unbind_all();
            channel2.unsubscribe();
          };
        }
      }, [activeChat, rolesData.shop_id]);
  
    const handleActive = (item,index) => {
      dispatch({ type: "SET_ACTIVE_CHAT_DATA", payload: item });
  

      let filterlistdata = filterList
      filterlistdata[index].unread_chat_count = 0
      setFilterList(filterlistdata)
      axios.post(`${config.apiEndPoint}chat/updateMessageStatus`,{
        "id": item.sender_id,
        "user_type": "vendor",
        "sender_id": item.sender_id,
        "receiver_id": item.receiver_id,
        "message_sender_id": item.sender_id,
      })
        .then((response) => {
          axios.get(`${config.apiEndPoint}chat/getUserChat/${item.sender_id}/${rolesData.shop_id}`)
          .then((response) => {
            setLoading(false);
            dispatch({
              type: "SET_CHAT_ALL_DATA",
              payload: response.data.data,
            });
          })
          .catch((error) => {
            if (error.response.status === 401) toast.error(error.response.data.message);
            else toast.error(error.response.data.errorMessage);
          });
        })
        .catch((error) => {
          if (error.response.status === 401) toast.error(error.response.data.message);
          else toast.error(error.response.data.errorMessage);
        });
    };
  
    const sendMessage = () => {
      if (updateImage.message) {
        setLoading3(true);
        dispatch({
          type: "SET_UPDATE_IMAGE",
          payload: {
            image: "",
            message: "",
            value: "",
          },
        });
        axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenSession()}`,
        };
        axios
          .post(
            `${config.apiEndPoint}chat/sendMessage`,
            {
              message_sender_id: rolesData.id,
              sender_id: activeChat.sender_id,
              receiver_id: rolesData.shop_id,
              message: updateImage.message,
              user_type: "vendor",
            },  
            {
              headers: {
                'X-Socket-Id': Pusher.instances[0].connection.socket_id,
              },
            }
          )
          .then(() => {
            setLoading3(false);
            dispatch({
              type: "SET_UPDATE_IMAGE",
              payload: {
                image: "",
                label: "",
                value: "",
              },
            });
          })
          .catch((error) => {
            console.error('Error sending message:', error);
          });
      }
    };
  
    const handleInput = (e) => {
      setFilterList(activelist.filter((item) => item.name.toLowerCase().includes(e.toLowerCase())));
    };
  
    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        const path = (window.URL || window.webkitURL).createObjectURL(selectedFile);
        dispatch({
          type: "SET_UPDATE_IMAGE",
          payload: {
            image: path,
            label: "",
            value: selectedFile,
          },
        });
        dispatch({ type: "SET_GALLERY_ACTIVE", payload: true });
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    };
  
    const handleImageClose = () => {
      dispatch({
        type: "SET_UPDATE_IMAGE",
        payload: {
          image: "",
          label: "",
          value: "",
        },
      });
      dispatch({ type: "SET_GALLERY_ACTIVE", payload: false });
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    };

if(loading || loading2) return <Loader />;
    return (
        <div className="Discussions">
            <style>{`
                .dashboard__Body {
                    padding: 0;
                }
            `}</style>
            <div className="Discussions_ grid grid-cols-[3fr,7fr]">
                <div className="Discussions_left min-h-screen bg-white py-4 px-3">
                    <h2 className="text-[1.5rem] font-medium mb-4">Discussions</h2>
                    <div className="relative">
                        <input  type="text"  className="border border[#ddd] rounded-full w-full py-2 pl-10 outline-none" onInput={(e) => handleInput(e.target.value)}  /> <FaMagnifyingGlass className="absolute left-4 top-[50%] transform translate-y-[-50%]" />
                    </div>
                    <div className="Discussions_list mt-4">
                        <ul className="list">
                            {filterList.map((item,index) => {
                                const { id, profile_image, messages,name, last_message_at,sender_id,unread_chat_count  } = item;
                                let mestrue = false
                                let chats = {}
                                let aa =  messages.split("\n")
                                if(item.messages.includes("\n")) {
                                    mestrue = true;
                                    chats = {
                                        "product_image":aa[0],
                                        "product_name":aa[1],
                                        "product_price":aa[2]
                                    }
                                }
                                return (
                                    <li key={index} className={`grid Discussions_listl relative items-center  grid-cols-[auto,1fr] gap-3 mb-3 cursor-pointer ${activeChat.sender_id === sender_id ? "active" : ""}`} onClick={() => handleActive(item,index)}>
                                        <div className={`Discussions_listlll rounded-full relative  ${onlineUsers.includes(sender_id) ? "border-2 border-[green]" : ""}  `}>
                                            <img
                                                className="w-full h-full rounded-full"
                                                src={profile_image}
                                                alt="images.jpg"
                                                onError={(e)=> e.target.src = userImage}
                                            />
                                        {/* <span className="absolute bottom-0 right-[.5rem] w-2 h-2 rounded-full bg-green-700"></span> */}
                                        </div>
                                        <div>
                                            <div className="h2">{name}</div>
                                            <ul className="list">
                                                <li className="flex justify-between text-[#837878]">
                                                    <div className="text-[.7rem] leading-[.8rem]">{mestrue ? <div className="text-[#8696a0] flex gap-1"> <FaImage /> <span>Product</span></div> : messages}</div>
                                                    <div className="text-[.7rem] leading-[.8rem]">{last_message_at}</div>
                                                </li>
                                            </ul>
                                        </div>
                                        {unread_chat_count !== 0 && <span className="absolute top-2 right-3 bg-[#ed2656] w-5 h-5 grid place-items-center text-white rounded-full">{unread_chat_count}</span>}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="Discussions_right">
                    {activeChat.name ? <>
                        <div className="Discussions_right-top flex gap-2 items-center bg-white py-3 px-4 border-b border-[#ddd]">
                        <button className="lg:hidden block bg-[#ed2656] text-white px-2 py-2 rounded-lg">Back</button>
                        <div className="grid grid-cols-[auto,1fr] gap-2">
                            <div className="w-10 h-10">
                                <img
                                    className="w-full h-full rounded-full"
                                    src={activeChat?.profile_image}
                                    onError={(e)=> e.target.src = userImage}
                                    alt="images.jpg"
                                />
                            </div>
                            <div>
                                <div className="h2">{activeChat?.name}</div>
                                <div className="text-[.7rem] leading-[.8rem] capitalize">{onlineUsers.includes(activeChat.sender_id) ? "Online" : "Offline"}</div>
                            </div>
                        </div>
                    </div>
                    <div className="Discussions_right- relative">
                    <div ref={chatContainerRef} className="Discussions_right-center h-[80vh] overflow-y-auto p-4 scroll-smooth">
                        <div className="chat">
                         {(chatdata).map((ite,index) => {
    return (
        <div key={index}>
            <div className="text-center bg-gray-300 w-fit p-2 rounded-md mx-auto">{ite.date}</div>
            {ite.chat_data.map((item, index2) => {
                 let mestrue = false
                 let chats = {}
                 let aa =  item.messages.split("\n")
                 if(item.messages.includes("\n")) {
                     mestrue = true;
                     chats = {
                         "product_image":aa[0],
                         "product_name":aa[1],
                         "product_price":aa[2]
                     }
                 }
                return (
                     <div key={index2} className={`${item?.message_sender_id !== rolesData?.id ? "" : "rightSide ml-auto"} w-fit`} style={{ maxWidth: "50%" }}>
                                 <div className={`my-3 ${item?.message_sender_id === rolesData?.id ? "bg-[#ddd]" : "bg-[#ed2656]"} p-3 rounded-2xl`}>
                                 {mestrue ? (
  <>
     <img className="w-full rounded-3xl mb-2" src={chats?.product_image} alt="11" />
    <div className={`h4 ${item?.message_sender_id === rolesData?.id ? "" : "text-white"}`}> {chats?.product_name} </div>
    {/* {item?.rating && <Rate disabled allowHalf defaultValue={item?.rating} />} */}
 
      <div className={`h4 ${item?.message_sender_id === rolesData?.id ? "" : "text-white"}`}>{chats?.product_price}</div>

  </>
) : (
  <div className={`h4 ${item?.message_sender_id === rolesData?.id ? "" : "text-white"}`}>
    {item?.messages}
  </div>
)}

                                 </div>
                                 <div className={`flex items-center -mt-[.7rem] gap-2 ${item?.message_sender_id !== rolesData?.id ? "text-left justify-start" : "text-right justify-end"} `}>
                                 <span className={`text-[#5f5f5f] text-[12px]  block `}>{dayjs(item?.created_at).format('HH:mm')}</span>

                                 {/* {item?.message_sender_id === rolesData?.id && <svg viewBox="0 0 16 11" height="11" width="16" preserveAspectRatio="xMidYMid meet" className="" fill="none"><title>msg-dblcheck</title><path d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z" fill={`${item.}#252525`}></path></svg>} */}
                                 </div>
                                
                             </div>
                );
            })}
        </div>
    );
})}
                        </div>
                    </div>
                            
                    <div className="Discussions_right-bottom border-t border-[#ddd] bg-white py-3 px-5">
                        <div className="grid grid-cols-[1fr,auto] items-center gap-4">
                            <input
                            ref={fileInputRef}
                                onChange={handleFileChange}
                                type="file"
                                name="gallery"
                                id="gallery"
                                className="cursor-pointer w-0 h-0 opacity-0 absolute"
                            />
                            {/* <label htmlFor="gallery" className="cursor-pointer">
                                <GrGallery className="text-[#ed2656] text-[1.3rem]" />
                            </label> */}
                            <div className="w-full">
                                <input
                                    type="text"
                                    className="border border[#ddd] rounded-full w-full py-2 px-4 outline-none"
                                    placeholder="Start a new Message"
                                    value={updateImage?.message ?? ""}
                                    onChange={(e) => dispatch({ type: "SET_UPDATE_IMAGE", payload: { ...updateImage, message: e.target.value } })}
                                />
                            </div>
                            <button onClick={sendMessage} disabled={loading3} className={`w-10 dsdsds h-10 bg-[#ed2656] rounded-full grid place-items-center ${loading3 ? `cursor-progress` : `cursor-pointer`}`}> 
                                {
loading3 ?
<div  role="status">
<svg aria-hidden="true" className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
</svg>
<span className="sr-only">Loading...</span>
</div>
:
<IoMdSend className="text-[#fff] text-[1.3rem]" />

                                }
                                
                    
                            </button>
                        </div>
                    </div>
                    {galleryActive && (
                        <div className="chat2 absolute w-full h-full left-0 top-0 bg-white border border-[#ddd] border-t-0">
                            <div className="chat2_top p-4 cursor-pointer">
                                <svg
                                    onClick={handleImageClose}
                                    viewBox="0 0 24 24"
                                    height="24"
                                    width="24"
                                    preserveAspectRatio="xMidYMid meet"
                                    className=""
                                    fill="currentColor"
                                    enableBackground="new 0 0 24 24"
                                >
                                    <title>x</title>
                                    <path d="M19.6004 17.2L14.3004 11.9L19.6004 6.60005L17.8004 4.80005L12.5004 10.2L7.20039 4.90005L5.40039 6.60005L10.7004 11.9L5.40039 17.2L7.20039 19L12.5004 13.7L17.8004 19L19.6004 17.2Z"></path>
                                </svg>
                            </div>
                            <div className="chat2_bottom mt-14 text-center w-[80%] mx-auto">
                                {updateImage && <img src={updateImage.image} className="w-100 max-h-[15rem] object-cover" alt="" />}
                                <input
                                    type="text"
                                    value={updateImage.message}
                                    onChange={(e) => dispatch({ type: "SET_UPDATE_IMAGE", payload: { ...updateImage, message: e.target.value } })}
                                    className="mt-5 border border-[#ddd] w-full rounded-lg py-3 px-4 outline-none placeholder:[#ddd]"
                                    placeholder="Add a caption"
                                />
                            </div>
                            {/* <div className="chatFooter flex justify-end p-5">
                            <div  onClick={handleSubmit} className="w-10 h-10 bg-[#ed2656] rounded-full grid place-items-center cursor-pointer">
                                <IoMdSend className="text-[#fff] ddd text-[1.3rem]" />
                            </div>
                            </div> */}
                        </div>
                    )}
                    </div>
                    </> : <h3 className="py-3 px-5 grid items-center h-[100vh] justify-center">No Data</h3>}
                  
                   
                </div>
            </div>
        </div>
    );
};

export default Discussions;