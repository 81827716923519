import React, { useContext, useEffect, useState } from "react";
import FormControl from "../components/form/FormControl";
import { Field, Form, Formik } from "formik";
import SubmitButton from "../components/SubmitButton";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import config from "../services/config.json";
import { toast } from "react-toastify";
import File from "../components/form/File";
import swal from "sweetalert";
import { city } from "../data/alldata";
import usePost from "../customHooks/usePost";
import { MenuActive } from "../context/MenuActiveContext";

const VenderCreate = () => {
  const { setMunuActiv } = useContext(MenuActive);

  useEffect(() => {
    setMunuActiv([true,"VendorManagement"])

  }, [])
  const navigate = useNavigate();
  const [res, apiMethod] = usePost()
  const [image, setImage] = useState("");
  const [sellingPartsFor, setSellingPartsFor] = useState("Thermal Vehicle (Petrol - Diesel)");
  const [useDocument, setUseDocument] = useState("")
    const initialValues = {
    name: "",
    email: "",
    password: "",
    phone: "",
    city: "",
    address: "",
    vat_number: "",
    return_policy: "",
    terms_conditions: "",
    "vehicle_make":"",
    };
    const validationSchema = yup.object({
      email: yup.string().email("Invaild Email").required(),
      password: yup
        .string()
        .min(6, "Mininum 6 length")
        .max(20, "Maximum 20 length").required(),
        phone: yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .min(8)
        .required('A phone number is required'),
    });
    const handleSubmit = async (values,{ resetForm }) => {
      let formdata = new FormData();
      let Feilds = {
        "name": "Company Name",
        "email":"Email",
        "phone":"Phone",
        "city":"City",
        "address":"Address",
        "vat_number":"Tax ID/ VAT Number",
        "return_policy":"Return Policy",
        "terms_conditions":"Terms and Conditions",  
        "vehicle_make":"Make",
      }
      const requirdFeild = []
      if(image === "") {
        requirdFeild.push("Company Logo")
      }
      if(useDocument === "") {
        requirdFeild.push("Documents for verification")
      }
      if(sellingPartsFor === "") {
        requirdFeild.push("Vehicle Type")
      }
      for (let item in values) {
        if(values[item] === "" || values[item].length === 0) {
          requirdFeild.push(Feilds[item])
        }
        formdata.append(item,values[item])
      }
      if(requirdFeild.length > 0) {
        swal({
          title: "Must Required",
          text: requirdFeild.join(","),
          icon: "warning",
          dangerMode: true,
        });
      }
      
      else {
      
        formdata.append("logo",image)
        formdata.append("document",useDocument)
        formdata.append("vehicle_type",sellingPartsFor)
        apiMethod(`addVendor`,formdata);
        resetForm();
        setUseDocument("");
        setImage("")
      }
    };
    useEffect(() => {
      if(res.data) {
        if(res.data.status === "false") {
          toast.error(res.data.message);
        }
        else {
          toast.success(res.data.message);
          navigate(`/${config.demo}vendor/list`)
        }
      }
     
    }, [res.data,navigate])
    const vichledata = [
      {
        id:1,
        label: "Thermal Vehicle Petrol-Diesel"
      },
      { 
        id:2,
        label:"Electric Vehicle"
      },
      { 
        id:3,
        label:"Trucks and buses"
      },
      { 
        id:4,
        label:"off roads"
      },
      { 
        id:5,
        label:"others"
      },
    ]
    const Make = [
      "Mercedes",
      "BMW",
      "Range Rover",
    ]
    
    const handlesellingParts = (e) => {
      setSellingPartsFor(e.target.value)
    }
    
  return (
   <>
   
   <div className=" flex items-center px-10 py-5 ">
        <div className="SignInPage__right_ w-100 h-full">
          <Link to={`/${config.demo}vendor/list`} className="arrowArrow flex items-center ">
            <img src={require("../dist/webImages/arrow-left2.png")} alt="" />
            <span className="font-medium">Create Vendor Account</span>
          </Link>
          <br />
         
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange
          >
             {({ values, setFieldValue }) => (
            <Form name="myForm">
               {console.log(values)}
            <File
                    label={"Company Logo"}
                    value={image}
                    onUpload={setImage}
                    name={"logo"}
                    typeFile="image/*"
                  />

              <div className="SignInPage__rightBox grid grid-cols-2 gap-5">
                
                <div className="SignInPage__rightBoxl">
                 
                  <FormControl
                    name="name"
                    placeholder="Company Name"
                    label={"Company Name"}
                    className="w-full"
                    control="input"
                  />
                  <FormControl
                    name="email"
                    placeholder="Email Address"
                    label={"Email"}
                    className="w-full"
                    control="input"
                  />
                   <FormControl name="password" placeholder="Enter your Password" label={"Password"} className="w-full" control="password" />

                  <FormControl
                    name="phone"
                    type={"tel"}
                    placeholder="Phone Number"
                    label={"Phone Number"}
                    className="w-full"
                    control="input"
                  />
                  <FormControl
                    name="city"
                    label={"City"}
                    className="w-full"
                    control="select"
                    custom_label_name={"name"}
                    firstSelect={"City"}
                    customer_value_name={"name"}
                    selectList={city}
                  />
                  <FormControl
                    name="address"
                    type={"text"}
                    placeholder="Address"
                    label={"Address"}
                    className="w-full"
                    control="input"
                  />
                     <FormControl
                    name="vat_number"
                    type={"number"}
                    placeholder="Tax ID  VAT Number"
                    label={"Tax ID/ VAT Number"}
                    className="w-full"
                    control="input"
                  />
                
                </div>
                <div className="SignInPage__rightBoxr">
                  <div className="inputfeild mt-3">
                    <label htmlFor="" className="mb-1 block">Vehicle Type:</label>
                    <select className="w-100" value={sellingPartsFor} onChange={(e) => handlesellingParts(e)} name="vehicle_type" id="">
                      {
                       vichledata.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>{item.label}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                  <div className="checkc mt-3">
                    <div className="checkc_">
                    <FormControl
                    name="vehicle_make"
                    label={"Make"}
                    placeholder="Make"
                    className="w-full"
                    control="input"
                  />
                  <small className="m-0">Ex: Mercedes - BMW - Range Rover</small>
                    </div>
                  </div>
                <FormControl
                    name="return_policy"
                    placeholder="Return Policy"
                    label={"Return Policy"}
                    className="w-full"
                    control="textarea"
                  />
                <FormControl
                    name="verification"
                    className="w-full"
                    control="FileDoument"
                    update={setUseDocument}
                    updateValue={useDocument}
                  />
                  <div className="term mt-5">
                  <div className="flex items-center ">
    <Field  id="default-radio-2" type="radio" value="1"  name="terms_conditions" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
    <label htmlFor="default-radio-2" className="ms-2 text-sm font-medium">Terms and Conditions</label>
</div>
                  
                  </div>

                  
              <SubmitButton
                props={{
                  class: "btn btn-primary  block mt-5 submit",
                  text: "Create Vendor Account",
                  
                }}
                buttonLoading={res.isLoading}
              />
                </div>
                <div>
               
                
                </div>
              </div>
            

            </Form>
             )}
          </Formik>
        </div>
      </div>
   </>
  )
}

export default VenderCreate