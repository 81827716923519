import React, { useEffect, useState } from 'react'
import config from "../services/config.json";
import { Link } from 'react-router-dom';
import { Modal, Space, Table } from 'antd';
import { getTokenSession } from '../utils/common';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import axios from 'axios';

const Tables = ({datass,update}) => {
  axios.defaults.headers = {
    "Content-Type": "multipart/form-data",
    "Authorization": `Bearer ${getTokenSession()}`,
  };
    const [keyDaata, setKeyDaata] = useState([])
    const [openRowData, setOpenRowData] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [InputValue, setInputValue] = useState({
      "name": ""
    })
    useEffect(() => {
        if(datass) {
         let AkeyDaata=  datass.map(v=>{ return {key:v.id,...v}})
         setKeyDaata(AkeyDaata)
        }
       }, [datass])

       function createOptions(data, prop) {
        console.log(data)
        return Array.isArray(data) && data
            .map(item => ({ text: item[prop], value: item[prop] }))
            .filter(item => item.value !== null)
            .reduce((acc, curr) => {
                const existingObj = acc.find(item => item.value === curr.value);
                if (!existingObj) {
                    acc.push(curr);
                }
                return acc;
            }, []);
    }

       const id = createOptions(keyDaata, 'id');
       const name = createOptions(keyDaata, 'name');
    let columns = [
        {
          key: 'id',
          title: 'id',
          dataIndex: 'id',
          filters: id,
          onFilter: (value, record) => String(record.name).includes(value),
          ellipsis: true,
        },
        {
          key: 'name',
          title: 'Name',
          dataIndex: 'name',
          filters: name,
          onFilter: (value, record) => String(record.name).includes(value),
          ellipsis: true,
        },
        {
            key: 'action',
            title: 'Action',
            fixed: 'right',
            render: (_, record) => (
              <Space size="middle">
               <div className='flex items-center gap-3 tableaction'>
                  <Link key={`edit-${record.id}`} to={`/${config.demo}category/edit/${record.id}`}>
                      <img src={require("../dist/webImages/edit.png")} alt="" />
                  </Link>
                  <Link key={`delete-${record.id}`}  onClick={() => handleDelete(record)}>
                      <img src={require("../dist/webImages/delete.png")} alt="" />
                  </Link>
               </div>
              </Space>
            ),
          },
    ]
    const handleDelete = (item) => {
      let formdata = new FormData();
      formdata.append("id",item?.id)
      swal({
          title: "Are you sure?",
          text: "Are you sure that you want to delete?",
          icon: "warning",
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
            axios.get(`${config.apiEndPoint}dashboard/deleteCategory/${item?.id}`)
            .then((response) => {
              toast.success(response.data.message);
              axios.get(`${config.apiEndPoint}dashboard/viewCategories`)
              .then((responses) => {
                update(responses.data?.data)
                })
                .catch((error) => {
                  if (error.response?.status === 401)
                    toast.error(error.response.data.message);
                  else toast.error(error.response.data.errorMessage);
                });
              })
              .catch((error) => {
                if (error.response?.status === 401)
                  toast.error(error.response.data.message);
                else toast.error(error.response.data.errorMessage);
              });
           
          }
       
        });
    };

  return (  
    <div>
      <Table scroll={{ x: 'max-content' }}  columns={columns} dataSource={keyDaata} />


    </div>
  )
}

export default Tables
